import React, { useState } from 'react';
import logo from './logo.svg';

function App() {
  const [code, setCode] = useState('');
  const [linkData, setLinkData] = useState(null);
  const [error, setError] = useState('');

  const handleRetrieveLink = async () => {
    setError('');
    setLinkData(null);
    if (!code) {
      setError('Please enter a code.');
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HOST}/shared-link/${code}`);
      setCode('');
      if (response.ok) {
        const data = await response.json();
        setLinkData(data);
      } else if (response.status === 404) {
        setError('That code does not exist or has expired.');
      } else if (response.status === 410) {
        setError('That code does not exist or has expired.');
      } else {
        setError('An error occurred. Please try again.');
      }
    } catch (err) {
      setError('Unable to retrieve link. Please check your network and try again.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <img src={logo} alt="SiteScreen Logo" className="mt-5 mb-5" style={{ width: '250px' }} />

      <div className="mb-10 w-5/6 md:w-1/3 bg-white p-6 rounded-lg border border-gray-200">
        <h2 className="text-lg font-semibold text-gray-700 mb-4 text-center">
          Retrieve a Shared Link
        </h2>
        <div className="flex flex-col gap-4">
          <input
            type="text"
            placeholder="Enter secret code here"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
          />
          <button
            onClick={handleRetrieveLink}
            className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500"
          >
            Retrieve Link
          </button>
          {error && (
            <div className="text-red-600 text-sm text-center">
              {error}
            </div>
          )}
          {linkData && (
            <div className="text-center mt-4">
              <p className="text-gray-600 text-xl">
                <span>This link is from</span>{' '}
                <span className="text-orange-600 font-bold">{linkData.domain}</span>
              </p>
              <div className='mt-3 text-indigo-600'>
                If you trust this domain, <a
                  href={linkData.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-700 font-semibold"
                >
                  open the link
                </a>.
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="w-5/6 mx-auto md:w-1/3 bg-white rounded-lg text-center">
        <p className="text-gray-600 mb-4">
          SiteScreen helps protect businesses, customers, and general Internet users from online scams and phishing attacks by verifying the authenticity of website pages and links.
        </p>
        <a
          href="https://forms.gle/qzPzkQYhGcMXPw71A"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-700 font-semibold"
        >
          Sign up for Early Access
        </a>
      </div>

    </div>
  );
}

export default App;
